import React from "react";
import { useEffect } from 'react'
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth"
import { versionNumber } from "./versionHisory";
import { themeChange } from 'theme-change'
import themePicker from "./themePicker";
import { BiTaxi } from 'react-icons/bi';

function Navbar(props) {
  const auth = useAuth();

  useEffect(() => {
    themeChange(false)
    // 👆 false parameter is required for react project
  }, [])

  return (

    <div className="navbar bg-base-300">
      <div className="navbar-start pl-4">
      <BiTaxi size="2em"/>
        <p className="normal-case text-xl pl-4 invisible md:visible">Taxi Fare Calculator</p>
      </div>
      <div className="navbar-end">
        {auth.user && (
          <>
            <p className="pr-4">{versionNumber}</p>
            
            {window.location.pathname !== "/" ? (
            <Link to="/" className="pr-4">
              <button className="btn btn-ghost btn-circle">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              </button>
            </Link>) 
            : (
              <Link to="/settings" className="pr-2">
              <button className="btn btn-ghost btn-circle">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </button>
            </Link>
            )}
            <div className="pr-2">
              {themePicker()}
            </div>
            <a href="/auth/signout" className="btn" onClick={(e) => {
              e.preventDefault();
              auth.signout();
              }}
              >Log Out</a>
          </>
        )}
      </div>
    </div>
  );
}

export default Navbar;