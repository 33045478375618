import React from "react";
import "./../styles/global.css";
import IndexPage from "./index";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import SettingsPage from "./settings";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/settings" component={SettingsPage} />

              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
