import { React, useState } from "react";
import Meta from "./../components/Meta";
import Navbar from "../components/Navbar";
import { requireAuth, useAuth } from "./../util/auth";
import { updateUser } from "../util/db";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SettingsPage() {
  const auth = useAuth();
  const [rate1_rate, setRate1_rate] = useState(auth.user.settings.rate1_rate);
  const [rate2_rate, setRate2_rate] = useState(auth.user.settings.rate2_rate);
  const [rate3_rate, setRate3_rate] = useState(auth.user.settings.rate3_rate);
  const [rate1_meter_start, setRate1_meter_start] = useState(auth.user.settings.rate1_meter_start);
  const [rate2_meter_start, setRate2_meter_start] = useState(auth.user.settings.rate2_meter_start);
  const [rate3_meter_start, setRate3_meter_start] = useState(auth.user.settings.rate3_meter_start);
  const [rate2_start_time, setRate2_start_time] = useState(auth.user.settings.rate2_start_time);
  const [rate2_end_time, setRate2_end_time] = useState(auth.user.settings.rate2_end_time);
  const [round, setRound] = useState(auth.user.settings.round);
  const [traffic_rate, setTraffic_rate] = useState(auth.user.settings.traffic_rate);
  const [seatMultiplier, setSeatMultiplier] = useState(auth.user.settings.extra_seat_multiplier);
  const [defaultStartLocation, setDefaultStartLocation] = useState(auth.user.settings.default_start_location);
  const [travelMode, setTravelMode] = useState(auth.user.settings.travel_mode || "car");
  const [routeType, setRouteType] = useState(auth.user.settings.route_type || "fastest");
  const hoursArray = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
  const hoursLabelArray = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
  const minutesArray = [0,15,30,45];
  const minutesLabelArray = ["00","15","30","45"];

  function handleSubmit(e) {
    e.preventDefault();
    updateUser(auth.user.uid, 
      {
        settings: 
          {
            rate1_rate: rate1_rate,
            rate2_rate: rate2_rate,
            rate3_rate: rate3_rate,
            rate1_meter_start: rate1_meter_start,
            rate2_meter_start: rate2_meter_start,
            rate3_meter_start: rate3_meter_start,
            rate2_start_time: rate2_start_time,
            rate2_end_time: rate2_end_time,
            traffic_rate: traffic_rate,
            round: round,
            extra_seat_multiplier: seatMultiplier,
            default_start_location: defaultStartLocation,
            travel_mode: travelMode,
            route_type: routeType
          }
      });
    toast.success('Settings Saved', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  return (
    <>
      <Meta />
      <meta 
        name='viewport' 
        content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' 
      />
      <Navbar />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <div className="md:flex md:space-x-8 px-2 py-4 md:px-8 md:py-8">
          <div className="card md:w-1/3 bg-base-300 shadow-xl">
            <div className="card-body">
              <h2 className="card-title pb-8">Rate Values</h2>
              <label className="label">
                <span className="label-text">Rate 1 per Mile:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-6/12 input-sm md:input-md md:max-w-xs" defaultValue={auth.user.settings.rate1_rate} onChange={e => setRate1_rate(parseFloat(e.target.value))} />
              <label className="label">
                <span className="label-text">Rate 2 per Mile:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-6/12 input-sm md:input-md max-w-xs" defaultValue={auth.user.settings.rate2_rate} onChange={e => setRate2_rate(parseFloat(e.target.value))} />
              <label className="label">
                <span className="label-text">Rate 3 per Mile:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-6/12 input-sm md:input-md max-w-xs" defaultValue={auth.user.settings.rate3_rate} onChange={e => setRate3_rate(parseFloat(e.target.value))} />
              <div className="divider"></div> 
              <label className="label">
                <span className="label-text">Rate 1 Meter Start:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-6/12 input-sm md:input-md max-w-xs" defaultValue={auth.user.settings.rate1_meter_start} onChange={e => setRate1_meter_start(parseFloat(e.target.value))} />
              <label className="label">
                <span className="label-text">Rate 2 Meter Start:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-6/12 input-sm md:input-md max-w-xs" defaultValue={auth.user.settings.rate2_meter_start} onChange={e => setRate2_meter_start(parseFloat(e.target.value))} />
              <label className="label">
                <span className="label-text">Rate 3 Meter Start:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-6/12 input-sm md:input-md max-w-xs" defaultValue={auth.user.settings.rate3_meter_start} onChange={e => setRate3_meter_start(parseFloat(e.target.value))} />
            </div>
          </div>
          <div className="pt-4 md:pt-0"></div>
          <div className="card md:w-1/3 bg-base-300 shadow-xl">
            <div className="card-body">
              <h2 className="card-title pb-8">Rate 2 Times</h2>
              
              <label className="label">
                <span className="label-text">Start time</span>
              </label>
              <div className="flex pt-2">
                <select className="select select-bordered w-2/16 select-sm md:select-md max-w-xs" onChange={(e) => setRate2_start_time((Number(e.target.value) * 60) + Math.floor((60 * rate2_start_time) % 3600 / 60)) } value={Math.floor(rate2_start_time / 60)}>
                  {hoursArray.map((i) => {
                    return <option key={"hour-:" + i} value={i}>{hoursLabelArray[i]}</option>
                  })}
                </select>
                <select className="select select-bordered w-2/16 select-sm md:select-md max-w-xs" onChange={(e) => setRate2_start_time(Number(e.target.value) + Math.floor((rate2_start_time / 60)) * 60) } value={Math.floor((60 * rate2_start_time) % 3600 / 60)}>
                  {minutesArray.map((i, arr) => {
                    return <option key={"min-:" + i} value={i}>{minutesLabelArray[arr]}</option>
                  })}
                </select>
              </div>
              <label className="label">
                <span className="label-text">End time</span>
              </label>
              <div className="flex pt-2">
                <select className="select select-bordered w-2/16 select-sm md:select-md max-w-xs" onChange={(e) => setRate2_end_time((Number(e.target.value) * 60) + Math.floor((60 * rate2_end_time) % 3600 / 60)) } value={Math.floor(rate2_end_time / 60)}>
                  {hoursArray.map((i) => {
                    return <option key={"hour-:" + i} value={i}>{hoursLabelArray[i]}</option>
                  })}
                </select>
                <select className="select select-bordered w-2/16 select-sm md:select-md max-w-xs" onChange={(e) => setRate2_end_time(Number(e.target.value) + Math.floor((rate2_end_time / 60)) * 60) } value={Math.floor((60 * rate2_end_time) % 3600 / 60)}>
                  {minutesArray.map((i, arr) => {
                    return <option key={"min-:" + i} value={i}>{minutesLabelArray[arr]}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="pt-4 md:pt-0"></div>
          <div className="card md:w-1/3 bg-base-300 shadow-xl">
            <div className="card-body">
              <h2 className="card-title pb-8">Misc</h2>
              
              <label className="label">
                <span className="label-text">Round Final Price</span>
              </label>
              <select className="select select-bordered w-2/3 md:w-6/12 select-sm md:select-md max-w-xs" defaultValue={auth.user.settings.round} onChange={e => setRound(e.target.value)}>
                <option value="up">Up</option>
                <option value="down">Down</option>
                <option value="no">No</option>
              </select>
              <label className="label">
                <span className="label-text">Traffic Rate per minute:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-6/12 input-sm md:input-md max-w-xs" defaultValue={auth.user.settings.traffic_rate} onChange={e => setTraffic_rate(parseFloat(e.target.value))} />
              <label className="label">
                <span className="label-text">Extra Seat Multiplier:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-6/12 input-sm md:input-md max-w-xs" defaultValue={auth.user.settings.extra_seat_multiplier} onChange={e => setSeatMultiplier(parseFloat(e.target.value))} />
              <label className="label">
                <span className="label-text">Default Starting Location:</span>
              </label>
              <input type="text" className="input input-bordered w-2/3 md:w-full input-sm md:input-md max-w-xs" defaultValue={auth.user.settings.default_start_location} onChange={e => setDefaultStartLocation(e.target.value)} />
              <label className="label">
                <span className="label-text">Travel Mode:</span>
              </label>
              <div className="btn-group pt-2">
                <input type="radio" name="routeType" data-title="Taxi" value="taxi" className="btn px-5" onChange={(e) => setTravelMode(e.target.value)} checked={travelMode === "taxi" ? true : false}/>
                <input type="radio" name="routeType" data-title="Car" value="car" className="btn px-5" onChange={(e) => setTravelMode(e.target.value)} checked={travelMode === "car" ? true : false}/>
                <input type="radio" name="routeType" data-title="Bus" value="bus" className="btn px-5" onChange={(e) => setTravelMode(e.target.value)} checked={travelMode === "bus" ? true : false}/>
              </div>
            </div>
          </div>
        </div>

        <div className="card-actions place-content-center px-8">
                <button className="btn btn-primary" onClick={handleSubmit}>Save</button>
              </div>
    </>
  );
}

export default requireAuth(SettingsPage);
